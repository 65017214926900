<template>
  <div class="row justify-content-center align-items-md-center" >
    <div class="col-md-8">
      <v-card elevation="10" :loading="loading">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Criar" }} Caixa
          </h3>
        </div>
        <div class="card-body p-4">
          <form action="">
            <div class="form-group row">
              <div class="col-md-11 " v-click-outside="clickout"  >
                <v-autocomplete
                  v-model="model"
                  :items="lista_documentos"
                  :loading="isLoading"
                  :search-input.sync="search2"
                  cache-items
                  :menu-props="{ value: menu_open }"
                  deletable-chips
                  item-text="search"
                  item-value="id"
                  label="  Procure ..."
                  multiple
    @click="handleClickInside"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Procute seus
                        <strong>documentos</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
          
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="info"
                      class="white--text"
                      v-on="on"
                    >
                      <b-icon
                        icon="file-earmark"
                        font-scale="0.9"
                        aria-hidden="true"
                      ></b-icon>
                      <span >  {{ item.nome_original.length > 30 ? item.nome_original.substr(0, 40) + '... ' :item.nome_original }}</span>
                  
                      <v-icon  @click="removeItem(selected)" light>mdi-close</v-icon>
    
                    </v-chip>
             
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      color="info"
                      class="text-h5 font-weight-light white--text"
                    >
                      <div>
                        <b-icon
                          icon="file-earmark"
                          font-scale="0.9"
                          aria-hidden="true"
                        ></b-icon>
                      
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.nome_original"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
             
              </div>
              <div class="col-md-1">   <v-btn class="ml-3 mt-4" @click.prevent="search()" color="primary" elevation="10" icon>
                <v-icon>mdi-file-search</v-icon></v-btn
              ></div>
            </div>

            <div class="form-group row">
              <div v-if="lista_campos != ''" class="col-md-4">
                <label class="col-md-12">Numeração:*</label>
                <input
                disabled
                  type="text"
                  class="form-control"
                  v-model="form.numeracao"
                  placeholder="Digite a Descricao..."
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Codigo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.codigo"
                  placeholder="Digite a Descricao..."
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">ordem:*</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.ordem"
                  placeholder="Digite a Descricao..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a Descricao..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import moment from 'moment'

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        descricao: null,
        numeracao: null,
        ordem:null,
        codigo: null,
      },
      loading:false,
    items: [],
    isLoading:false,

      model: null,
      search2: null,
      menu_open:false,

      verif: false,
    };
  },
  computed: {
    lista_documentos() {
      return this.$store.state.documentos.lista_documentos;
    },
   
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.caixa.mensagem_alert;
    },
   
  },
  watch: {
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },

  },
  created() {
    this.preenxerCampos();
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` un Grupo no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
 let data = {caixa:this.form,documentos:this.model} 
      await this.$store.dispatch("caixa/create_caixa", data);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "caixa",
      });
    },
    async update() {
      this.verif = true;
      let data = {caixa:this.form,documentos:this.model} 

      await this.$store.dispatch("caixa/update_caixa", data);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "caixa",
      });
    },
    async preenxerCampos() {

      this.loading = true
      this.verif = true

      this.form.codigo =moment().format('DD.MM.HH.mm.ss')
      
      await this.$store.dispatch("configUsuarios/listar_usuarios");

      if (this.lista_campos != ""){
      await  this.$store.dispatch("documentos/listar_documentos",{pesquisa:''});
        this.form = {
          id: this.lista_campos.id,
        descricao:  this.lista_campos.descricao,
        numeracao:  this.lista_campos.numeracao,
        ordem: this.lista_campos.ordem,
        codigo:  this.lista_campos.codigo,
        };
        let a = []
        this.lista_campos.caixa_documento.map((item)=>{
           a.push(item.documentos_id) 
        })
        this.model = a;
      }
      this.loading = false
      this.verif = false


    },
    removeItem(index) {
      // Remove o item no índice especificado
      this.model.splice(index, 1);
    },
    async search() {
      // Items have already been loaded
    const a = this.search2
    this.search2 = ''
      this.isLoading = true;
      
    await  this.$store.dispatch("documentos/listar_documentos",{pesquisa:a});
      this.items = this.lista_documentos;
   
      this.menu_open = true
      this.isLoading = false;
    this.search2 = a
    },
 
    handleClickInside() {
      this.menu_open =  true;
    },
    clickout(){
        this.menu_open =  false;


    }
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: #8cdde2;
}
</style>